import { gsap } from 'gsap'

// Burger Initialization (i.e. page load animation)
const burgerInitTl = gsap.timeline({
	paused: true,
	reversed: true,
})

burgerInitTl
	.to('.bar', {
		x: function (index) {
			return index * -8
		},
		stagger: 0.1,
		duration: 0.13,
	})
	.to(
		'.bar.last',
		{
			rotation: 8,
			duration: 0.2,
		},
		'>+=0.5'
	)

// Burger "click" animation
const burgerPlayTl = gsap.timeline({
	paused: true,
	reversed: true,
})

burgerPlayTl
	.to('.bar.last', {
		rotation: 0,
		duration: 0.2,
	})
	.to(
		'.bar',
		{
			x: 0,
			duration: 0.13,
			stagger: {
				from: 3,
				each: 0.1,
			},
		},
		'>'
	)
	.to(
		'.bar',
		{
			opacity: function (index) {
				if (index === 0 || index === 1) {
					return 1
				} else {
					return 0
				}
			},
			duration: 0.01,
		},
		'>'
	)
	.to(
		'.bar',
		{
			rotation: function (index) {
				return index === 0 ? 45 : index === 1 ? -45 : 0
			},
			x: -12,
			duration: 0.2,
		},
		'>'
	)

export default {
	play() {
		burgerPlayTl.reversed() ? burgerPlayTl.play() : burgerPlayTl.reverse()
	},
	init() {
		burgerInitTl.play()
	},
}
