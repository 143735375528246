import QuoteSlider from '../lib/quoteSlider'
import Sparkles from '../lib/sparkles'
import Plyr from 'plyr'

export default {
	init() {
		// console.log('home')

		new QuoteSlider('quote-slider')

		Sparkles.init({
			canvas: $('.section--home-hero canvas'),
			speed: 0.25,
		})

		// plyr (Weirdwood trailer)
		new Plyr('#player')
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
}
