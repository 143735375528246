import jQuery from 'jQuery'
import './style.scss'

import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import privacyPolicy from './routes/privacy-policy'

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
	privacyPolicy,
})

/** Load Events */
jQuery(function () {
	routes.loadEvents()
})
