import { FocusTrap, Appear, ScrollEffects } from 'pickles'
import Burger from '../lib/burger'
import Drawer from '../lib/drawer'
import '../lib/gsap-polyfill'
import SmoothScroll from '../lib/smooth-scroll'

export default {
	init() {
		window.$ = window.$ || jQuery

		// JavaScript to be fired on all pages
		// console.log('common')

		// Smooth Scroll
		SmoothScroll.init()

		// Appear
		const appear = new Appear()

		// drawer nav
		const focusTrap = new FocusTrap('#drawer')

		// scroll effects
		const scrollEffects = new ScrollEffects()

		// smooth scroll
		// SmoothScroll.init()

		function toggleDrawer() {
			Drawer.play()
			if ($('body').hasClass('drawer--open')) {
				$('body').removeClass('drawer--open')
				$('[data-drawer-toggle]').attr('aria-expanded', false)
				focusTrap.stop()
			} else {
				$('body').addClass('drawer--open')
				$('[data-drawer-toggle]').attr('aria-expanded', true)
				focusTrap.start()
			}
		}

		$('.burger-button').on('click', function () {
			Burger.play()
			toggleDrawer()
		})

		// there's a hidden close button inside the drawer so that it can be closed using the keyboard
		// the visible burger button isn't focusable because it's outside the drawer FocusTrap
		$('#drawer .close-menu').on('click', function () {
			Burger.play()
			toggleDrawer()
		})

		// run reverse animations when user closes drawer by clicking outside it (i.e. not using the burger)
		$('#content').on('click', function () {
			if ($('body').hasClass('drawer--open')) {
				Burger.play()
				toggleDrawer()
			}
		})

		let windowWidth = $(window).width()

		// close drawer when screen WIDTH changes
		$(window).on('resize', function () {
			if (
				$('body').hasClass('drawer--open') &&
				windowWidth !== $(window).width()
			) {
				toggleDrawer()
				Burger.play()
			}
			windowWidth = $(window).width()
		})

		setTimeout(() => Burger.init(), 0)
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
}
