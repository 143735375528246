class QuoteSlider {
	/**
	 *
	 * @param {string} containerId - the id of the slider container
	 * @param {Object} [options] - slider config options
	 * @param {String} [options.nextClass=.next-slide] - the class name of the 'next' button
	 * @param {String} [options.prevClass=.prev-slide] - the class name of the 'prev' button
	 */
	constructor(
		containerId,
		options = { nextClass: '.next-slide', prevClass: '.prev-slide' }
	) {
		this.container = document.getElementById(containerId)
		this.nextButton = this.container.querySelector(options.nextClass)
		this.prevButton = this.container.querySelector(options.prevClass)
		this.slides = this.container.querySelectorAll('.slide')
		this.totalSlides = this.slides.length
		this.slide = 0
		this.heightOfTallestSlide = this.#getHeightOfTallestSlide()
		this.#init()
		this.#setContainerHeight()
		this.#goToSlide(0)
	}

	#init() {
		this.nextButton.addEventListener('click', () => this.#next())
		this.prevButton.addEventListener('click', () => this.#prev())
		window.addEventListener('resize', () => this.#setContainerHeight())

		if (this.totalSlides <= 1) {
			this.nextButton.style.display = 'none'
			this.prevButton.style.display = 'none'
		}
	}

	#next() {
		this.slide === this.totalSlides - 1 ? (this.slide = 0) : (this.slide += 1)
		this.#goToSlide(this.slide)
	}

	#prev() {
		this.slide === 0 ? (this.slide = this.totalSlides - 1) : (this.slide -= 1)
		this.#goToSlide(this.slide)
	}

	#getHeightOfTallestSlide() {
		let tallest = 0

		this.slides.forEach((slide) => {
			slide.offsetHeight > tallest ? (tallest = slide.offsetHeight) : false
		})

		return tallest
	}

	#setContainerHeight() {
		// console.log(this.heightOfTallestSlide)
		this.container.style.minHeight = `${this.heightOfTallestSlide}px`
	}

	#goToSlide(idx) {
		if (idx >= 0 && idx <= this.totalSlides - 1) {
			for (let s = 0; s <= this.totalSlides - 1; s++) {
				if (s === idx) {
					this.slides[s].classList.remove('hidden')
					this.slides[s].classList.add('active')
				} else {
					this.slides[s].classList.remove('active')
					this.slides[s].classList.add('hidden')
				}
			}
			return true
		} else {
			console.error(`ERROR: QuoteSlider.goToSlide(${idx}): Index out of range`)
			return false
		}
	}
}

export default QuoteSlider
