import Sparkles from '../lib/sparkles'

export default {
	init() {
		// console.log('privacy-policy')

		Sparkles.init({
			canvas: $('.section--home-hero canvas'),
			speed: 0.25,
		})
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
}
