// dummy class so that TimelineLite class is available to pickles
import { gsap } from 'gsap'

if ( !window.TimelineLite ) {
    class TimelineLite {
        constructor(options) {
            return gsap.timeline(options)
        }
    }
    window.TimelineLite = TimelineLite
}
