import { gsap } from 'gsap'

const drawerTl = gsap.timeline({
	paused: true,
	reversed: true,
})

const drawer = document.querySelector('#drawer')
const navItems = drawer.querySelectorAll('.menu-item')

drawerTl
	.set(drawer, { visibility: 'visible' })
	.to(drawer, {
		top: 0,
		ease: 'power.out',
		duration: 0.6,
	})
	.to(
		navItems,
		{
			y: 0,
			opacity: 1,
			duration: 0.2,
			stagger: 0.1,
		},
		'>'
	)

export default {
	play() {
		drawerTl.reversed()
			? drawerTl.timeScale(1).play()
			: drawerTl.timeScale(2).reverse()
	},
}
